
import React from 'react';
import { Helmet } from 'react-helmet';
const Insights = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Helmet>
        <title>Nestheads - Insight</title>
        <meta name="description" content="Insight description" />
        {/* Other head tags */}
      </Helmet>
      <h1>Insights</h1>
    </div>
  );
};

export default Insights
  ;