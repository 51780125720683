import React, { useState, useEffect, useRef } from "react";
import { auth, firebase } from '../firebase'
import Api from "../services/Api";
import { Icon } from "react-icons-kit";
import { useNavigate } from "react-router-dom";
import { ic_logout } from 'react-icons-kit/md/ic_logout'
import { Alert, Modal } from "react-bootstrap";
import Buttons from '../components/Buttons'
import { useDispatch } from "react-redux";
import { login } from "../actions/userAction";
import { useSelector } from "react-redux";
import { logout } from "../actions/userAction";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const LandingPage = () => {

    const [_alert, setAlert] = useState({});
    const [_model, setModel] = useState('')
    const [show, setShow] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [terms, setTerms] = useState("Terms of Services")
    const [privacy, setPrivacy] = useState("Privacy Policy")
    const [_helmet, setHelmet] = useState({ title: 'Nestheads/login', des: 'login' })

    const popupRef = useRef(null);
    const contentref = useRef(null)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation()

    const user = useSelector((state) => state?.user)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const soclogin = async (fuser) => {
    //     setProcessing(true)
    //     console.log(fuser)
    //     let { first_name, last_name, email } = fuser.data
    //     if (!first_name || !last_name || !email) {
    //         setAlert({ variant: 'danger', message: "Data not provided" })
    //         return
    //     }
    //     let data = {
    //         "first_name": first_name,
    //         "last_name": last_name,
    //         "email": email
    //     }
    //     let res = await Api.post(`user/social/login`, data);

    //     setProcessing(false)
    //     if (res.success) {
    //         localStorage.setItem("waitlist", fuser.data.email)
    //         setProcessing(false)
    //         navigate('/waitlist')
    //     } else {
    //         setAlert({ message: res.message || "Unable to process your request", variant: 'danger' });
    //     }
    // }

    const soclogin = async (fuser) => {
        if (!fuser.data.email) {
            setAlert({ message: 'Required data not available', variant: 'danger' })
            return
        }

        let res = await Api.post(`auth/loginwith/${fuser.provider}/${fuser.identifier}`, fuser.data);
        if (res.success) {
            if (res.data.action == 'complete_profile') {
                let usr = res.data?.user;
                let url = `auth/completeregistration/${usr?.provider?.name}/${usr?.provider?.identifier}/${usr?.provider?.id}`
                navigate('/complete-profile', { state: { userData: res.data.user, url } })
            } else {
                dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                Api.setToken(res.data.accessToken).then(() => {
                    navigate("/")
                })
            }
        } else {
            setAlert({ message: res.message || "Unable to process your request", variant: 'danger' });
        }
    }


    const parseSLUser = (user, proID) => {
        var _user = {};
        if (user) {
            var name = (user?.displayName || '').trim();
            var firstName = '', lastName = '';
            if (name.indexOf(' ') !== -1) {
                name = name.split(' ');
                firstName = name[0]; delete name[0];
                lastName = name.join(' ');
            } else {
                firstName = name;
            }

            var email = user?.email || null;
            var phone = user?.phoneNumber || null;
            var pData = user?.providerData && user?.providerData[0] || null;
            if (pData.providerId !== proID && user.providerData.length > 1) {
                user.providerData.map((e) => {
                    if (e.providerId == proID) {
                        pData = e
                        return
                    }
                })
            }
            // console.log(pData?.providerId, pData?.provider, user?.providerId)
            _user = {
                identifier: pData?.uid || user?.uid,
                provider: (pData?.providerId || pData?.provider) || user?.providerId,
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    photo: user?.photoURL || null, // photourl
                    emailverify: user?.emailVerified ? 1 : 0,
                }
            }
            if (_user.provider)
                _user.provider = _user.provider.toString().replace(".com", '');
        }

        return _user?.identifier ? _user : false;
    };

    const signInWithGoogle = () => {
        setAlert({})
        let provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const signInWithApple = () => {
        setAlert({})
        let provider = new firebase.auth.OAuthProvider('apple.com');
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser, provider.providerId)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const _logout = () => {
        Api.get('auth/logout').then(res => {
            if (res) {
                dispatch(logout());
                navigate('/login')
            }
        });
    }

    const setModelData = (data) => {
        handleShow();
        setModel(data);
    }

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getTermandprivacy = async () => {

        let terms = await Api.get("page/privacy-policy")
        let privacy = await Api.get("page/terms-services")

        Promise.all([terms, privacy])
            .then((res) => {
                // console.log(res)
                if (res[0].success) {
                    setPrivacy(res[0].data.details)
                }
                if (res[1].success) {
                    setTerms(res[1].data.details)
                }
            })

    }

    useEffect(() => {
        getTermandprivacy()
    }, [])

    useEffect(() => {
        if (location.pathname == '/waitlist') {
            if (user?.isLogged) {
            } else {
                navigate("/login")
            }
        } else if (location.pathname == '/login' || location.pathname == '/') {
            if (user?.isLogged) {
                navigate('/waitlist')
            } else {
            }
        }
    }, [location])

    return (
        <div className="landing_page" >
            <Helmet>
                <title>{_helmet.title}</title>
                <meta name="description" content={_helmet.des} />
            </Helmet>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontFamily: 'Dimbo' }} >{_model}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {_model == 'Terms of Services' ? terms : privacy}
                </Modal.Body>
            </Modal>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div ref={contentref} className="model_nestheads">
                        <div style={{ backgroundColor: "#ccc", width: "100%", borderRadius: '20px', textAlign: 'center', fontFamily: 'Dimbo', fontSize: '20px', overflow: 'hidden' }} >
                            <div style={{ borderBottom: '3px solid black', padding: '20px' }} >Nestheads are parasites in your cognition.</div>
                            <div className="landing_model_data" >
                                <div style={{ padding: '10px 0', fontWeight: 'bold' }} >What are Nestheads?</div>
                                Nestheads represent those challenging thoughts and feelings we all experience, like fear, envy, or even perfectionism. They're like uninvited guests in our minds, often steering us away from our true goals and potential.<br />

                                <div style={{ padding: '10px 0', fontWeight: 'bold' }} >Why Do Nestheads Exist?</div>
                                Our brains, in their attempt to be efficient, sometimes create these mental shortcuts or biases. While they might have been useful in the past, many Nestheads don't serve us well in the modern world, leading to unnecessary stress and misconceptions.<br />

                                <div style={{ padding: '10px 0', fontWeight: 'bold' }} >Understanding Nestheads: Why It's Crucial</div>
                                Knowing your Nestheads means recognizing these unhelpful patterns in your thinking. By identifying them, you can better manage your reactions and decisions, leading to a more fulfilling and controlled life experience.<br />

                                <div style={{ padding: '10px 0', fontWeight: 'bold' }} >How Can We Deal With Nestheads?</div>
                                The first step is awareness. Understanding that these feelings and biases are common and can be managed is empowering. It’s about learning to navigate our minds more effectively, turning challenges into opportunities for growth.<br />

                                <div style={{ padding: '10px 0', fontWeight: 'bold' }} >Take Charge of Your Nestheads</div>
                                Embark on a journey of self-discovery and mental mastery. By recognizing and understanding your Nestheads, you can start to reshape your thinking and reactions, steering your life towards your true aspirations and potential.<br />
                                <div style={{width:'fit-content' , margin:'0px auto'}} >
                                    <Buttons title={"Discover Your Nestheads"} onClick={() => { user.isLogged ? navigate("/chat") : navigate("/models") }} />
                                </div>
                                <div style={{ padding: '10px 0', fontWeight: 'bold' }}  >A quick note from Nestheads</div>
                                <div>Thank you for getting this far in your quest for seeing the value of learning about your cognition. We have poured our existence and the hope of our children’s happiness in this project. We have been scientifically rigorous with design implementation and we have created a lot of art(pre-ai-art).</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
            <div className="left_side">
                <div style={{ position: 'absolute', top: '10px', left: '30px', cursor: 'pointer' }} onClick={() => navigate()} >
                    <img src={require('../assets/logo.png')} width={180} height={50} alt="Nestheads logo" />
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ flex: '1' }} ></div>
                    <div style={{ borderRadius: "20px", padding: '0 20px' }} >
                        <video loop={true} autoPlay={true} muted playsInline className="w-auto  h-auto " id="myVideo" style={{ borderRadius: "20px", maxWidth: '100%', maxHeight: '360px', objectFit: 'cover', border: '4px solid black' }} poster={require('../assets/poster.png')} >
                            {/* <source src="https://cdn.pika.art/entry.mp4" type="video/mp4" />
                            <source src="https://cdn.pika.art/entry.webm" type="video/webm" /> */}
                            <source src={require("../assets/nestheads.mp4")} type="video/mp4" />
                            <source src={require("../assets/nestheads.webm")} type="video/webm" />
                        </video>
                    </div>
                    <div className="nestheads_link"  >
                        <div className="nestheads_link_text" onClick={togglePopup}>What are nestheads?</div>
                    </div>
                    {/* <div className="bottom_text" >
                        <img src={require('../assets/nesthead_bottom_text.png')} alt="Gamified self help" />
                    </div> */}
                </div>
            </div>
            {
                !user.isLogged ?
                    <div className="right_side">
                        <>
                            <div style={{
                                fontFamily: 'Dimbo', fontSize: "50px", lineHeight: '55px', margin: '20px 0px', padding: "0px 50px", textAlign: "center"
                            }} >READY TO TRY NESTHEADS?</div>
                            <div style={{ width: '70%' }} >
                                {_alert.message && <Alert style={{ width: "100%" }} dismissible={true} variant={_alert.variant}  >
                                    {_alert.message}
                                </Alert>}
                            </div>
                            <div style={{ fontFamily: 'Dimbo', fontSize: "20px", marginBottom: '12px' }} >Sign in to join the waitlist</div>
                            <div className='google-btn' style={{ backgroundColor: 'white', width: '70%' }} onClick={signInWithGoogle}>
                                <img style={{ marginRight: 10 }} alt='google' src={require('../assets/google.png')} height={23} width={23} />
                                Sign up with Google
                            </div>
                            <div onClick={signInWithApple} className='google-btn' style={{ backgroundColor: 'white', width: '70%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <img style={{ marginRight: 10 }} alt='apple' src={require('../assets/apple.png')} height={23} width={23} />
                                    <div style={{ display: 'inline' }}>Sign up with Apple</div>
                                </div>
                            </div>
                            <div style={{ color: 'white', margin: '15px 0', fontWeight: '600', textAlign: "center" }} >By signing in you are agreeing to the <span style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setModelData('Terms of Services')} >Terms of Services</span> and <span style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setModelData('Privacy Policy')} >Privacy Policy</span>.</div>
                        </>
                    </div>
                    :
                    <div className="right_side_waitlist">
                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column" }} >
                            <div style={{ width: '90px', height: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "50%", margin: '20px 0 0' }} >
                                {/* <Icon icon={userCheck} size={30} /> */}
                                <img src={require('../assets/will thumbs up.png')} alt="Nesthead thumbs up" width={'100%'} height={'100%'} />
                            </div>
                            <div onClick={_logout} style={{ cursor: 'pointer', margin: '20px ', color: "rgb(94, 94, 94" }}  ><Icon icon={ic_logout} size={20} /> Logout</div>
                        </div>
                        <div className="waitlist_joined" >
                            <h1 style={{ fontSize: '40px', fontWeight: '800', lineHeight: '42px', fontFamily: 'Dimbo' }} >
                                YOU'RE ON THE WAITLIST!
                            </h1>
                            <div style={{ fontSize: '18px', fontWeight: '500', lineHeight: '20px', padding: "30px 0", borderBottom: "1px solid gray" }}>
                                We'll send you an invitation link once we have a free spot. Check your mailbox and stay tuned.
                            </div>
                            <div style={{ margin: '10px 0' }} >
                                <span style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setModelData('Terms of Services')}>Terms of Services</span> and <span style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setModelData('Privacy Policy')}>Privacy Policy</span>.
                            </div>
                        </div>
                    </div>
            }
        </div >
    )
}

export default LandingPage