import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'

const Buttons = ({ title, onClick, loading = false, variant = 'default', disabled }) => {

    const [loading_, setLoading] = useState(false)

    useEffect(() => {
        setLoading(loading)
    }, [loading])

    if (variant == 'transparent') {
        return (
            // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <div onClick={disabled ? () => { } : onClick} style={{ cursor: 'pointer', color: disabled ? "gray" : "black", fontFamily: 'Dimbo', backgroundColor: 'white', borderRadius: "50px", padding: "10px 35px", marginTop: '10px', textAlign: 'center', fontSize: '15px', border: '2px solid black', cursor: 'pointer' }} >{loading_ ? <Spinner size='sm' /> : title}</div>
            // </div>
        )
    }
    return (
        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <div onClick={disabled ? () => { } : onClick} style={{ cursor: 'pointer', color: disabled ? "gray" : "white", fontFamily: 'Dimbo', backgroundColor: 'black', borderRadius: "50px", padding: "10px 35px", marginTop: '10px', textAlign: 'center', fontSize: '15px' ,border: '2px solid black', }} >{loading_ ? <Spinner size='sm' /> : title}</div>
        // </div >
    )

}

export default Buttons