import React, { useEffect, useState } from 'react';
import ModelListing from '../components/ModelListing';
import { useNavigate } from 'react-router-dom';
import CharacterDetails from './CharacterDetails';
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Models = () => {

  const [page, setPage] = useState('')
  const [Character, setCharacter] = useState({})
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState([])
  const [_helmet, setHelmet] = useState({ title: "models", des: 'models description' })
  const [loaded, setLoaded] = useState(true)

  const isLogged = useSelector((state) => state.user.isLogged)

  const navigate = useNavigate();
  const location = useLocation();

  const details = (e, ele) => {
    ele.preventDefault()
    setPage('details')
    if (isLogged) {
      navigate(`/models/${(e?.seo || 'model')}`)
    } else {
      navigate((e?.seo || 'model'))
    }
  }

  const updateLike = (e) => {
    Api.post('characters/like',
      {
        type: e.liked ? 0 : 1,
        character_id: JSON.stringify(e.id)
      })
      .then((res) => {
        if (res.success) {
          let _data = []
          data.map((ele) => {
            if (ele.id == e.id) {
              ele['liked'] = e.liked ? false : true
              _data.push(ele)
            } else {
              _data.push(ele)
            }
          })
          // console.log(_data)
          setData(_data)
        } else {

        }
      })
      .catch(() => {

      })
  }

  useEffect(() => {
    setLoading(true)
    // console.log(location.pathname)

    let _loc = location.pathname.split("/");
    if (_loc[_loc.length - 1] == 'models') {
      setPage('');
      setCharacter({})
      setHelmet({ title: "models", des: 'models description' });
      let url = isLogged ? 'characters/list' : 'characters/public/list'
      Api.post(url)
        .then((res) => {
          if (res.success) {
            setData(res.data)
          } else {
            setData([])
          }
        })
        .catch((err) => {
          console.log(err)
          setData([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
    else if (_loc[_loc.length - 1].trim() == '') {
      if (_loc[_loc.length - 2] == "models") {
        navigate(`/models`)
        setPage('');
        setCharacter({})
        setHelmet({ title: "models", des: 'models description' });

      } else {
        setPage('details')
        navigate(`/models/${_loc[_loc.length - 2]}`)
      }
    }

    else {
      if (_loc.length) {
        let character = _loc[_loc.length - 1]
        let id = character.split('-')[0]
        setLoading(true)
        document.title = character
        setHelmet({ title: character, des: 'character description' });
        // let url = isLogged ? id ? `characters/detail/${id}` : `characters/public/detail/${character}` : `characters/public/detail/${character}`
        let url = `characters/public/detail/${character}`
        Api.post(url)
          .then((res) => {
            if (res.success) {
              setCharacter(res.data)

              if (res.data.chat_messages) {
                const sortedArray = res.data.chat_messages.sort((a, b) => a.msg_id - b.msg_id);
                setHistory(sortedArray);
              }
              setPage('details')
            } else {
              setCharacter({})
              setPage('details')
            }
          })
          .catch(() => {
            navigate('/models')
            setPage('');
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        navigate('/models')
        setPage('');
      }
    }
  }, [location])

  useEffect(() => {
    // console.log(_helmet)
    setLoaded(false)
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [_helmet])

  return (
    <div className='model_page'>
      {
        page == "details" ?
          <CharacterDetails Character={Character} loading={loading} history={history} />
          :
          <div className='character_listing_container' >
            <div className='charactor_filter_container' >
              <div className='character_filter'>
                Filter by <img src={require('../assets/filter.png')} />
              </div>
            </div>
            <ModelListing data={data} onClick={details} updateLike={updateLike} loading={loading} />
          </div>
      }
    </div>
  );
};

export default Models;