import React, { useState, useEffect } from 'react'
import { Modal, Button, Alert, Spinner } from 'react-bootstrap'
import Buttons from '../../components/Buttons'
import CheckoutInputField from '../../components/CheckoutInputField'
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled'
import { Icon } from 'react-icons-kit'
import { useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../../components/PaymentForm'
import Switch from '../../components/Switch'
import CustomModel from '../../components/CustomModel'
import PaymentModel from '../../components/PaymentModel'
import PlanExpired from '../../components/PlanExpired'
import Api from '../../services/Api'

const stripePromise = loadStripe('pk_test_b3pirTgpoitBkTXvhE42PViU00s3SpNqVW');

const Subscription = () => {

    let opp = false
    let planExpired = true

    const plans = [
        {
            id: 1,
            name: 'Basic',
            price: "0",
            serviceCharge: '3',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            details: [
                "5 Chats",
                "1 Free Nestheads Finder Report",
                "Unlimited Words in Chat"
            ]
        },
        {
            id: 2,
            name: 'Premium',
            price: "44469",
            serviceCharge: '3',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            active: true,
            recommended: true,
            details: [
                "Everything in Basic +",
                "300 Chats",
                "Unlimited Words in Chat",
            ]
        },
        {
            id: 4,
            name: 'Epic',
            price: "44469",
            serviceCharge: '3',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            details: [
                "Everything in Basic +",
                "Everything in Basic +",
                "300 Chats",
                "300 Chats",
                "300 Chats",
                "Unlimited Words in Chat",
            ]
        },
    ]

    let _subscribed = {
        id: 2,
        name: 'Epic',
        price: "469",
        serviceCharge: '3',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        details: [
            "Everything in Basic +",
            "Everything in Basic +",
            "300 Chats",
            "300 Chats",
            "300 Chats",
            "Unlimited Words in Chat",
        ]
    }

    let history = [
        {
            date: '20-12-23',
            plan: 'Basic',
            amount: 'Free',
            status: true
        },
        {
            date: '20-12-23',
            plan: 'Epic',
            amount: '19.99',
            status: false
        },
        {
            date: '20-12-23',
            plan: 'Premium',
            amount: '9.99',
            status: false
        },
    ]

    const [selectedPlan, setSelectedPlan] = useState({});
    const [loading, setLoading] = useState(false);
    const [subscribed, setSubscribed] = useState({});
    const [secret, setSecret] = useState({ id: '', clientSecret: '' });
    const [_alert, setAlert] = useState({ message: '', variant: '' });

    const [yearly, setMonthly] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showPaymentModel, setShowPaymentModel] = useState(false);
    const [showPlanExpiredModel, setShowPlanExpiredModel] = useState(false);
    const [modelData, setModelData] = useState({ title: '', description: '', onClick: null });

    const userData = useSelector((state) => state.user.data);

    const activate = (e) => {

        if (e.price == "free" || e.price == "0") {
            setLoading(true);
            setTimeout(() => {
                setSubscribed(e)
                setAlert({ message: 'Payment successful.', variant: 'success' })
                setLoading(false)
                window.scroll(0, 0)
            }, 2000)
            return
        }
        setSelectedPlan(e);
        setShowPlanExpiredModel(false)
        setLoading(true)
        // setSecret({ id: "", clientSecret: 'pi_3OWab6GPynOt4PZC18aS2agz_secret_CN5GiLKVr5vgswvugerVqd561' })
        // setShowPaymentModel(true)

        Api.post('user/payment/intent', {
            "amount": parseInt(e.price),
            "currency": "inr",
        })
            .then((res) => {
                if (res.success) {
                    setSecret({ id: res.data.data.id, clientSecret: res.data.data.client_secret })
                    setShowPaymentModel(true)
                } else {
                    setAlert({ message: res.error.message || 'some unknown error occured!', variant: 'danger' })
                }
            })
            .catch((res) => {
                setAlert({ message: 'some unknown error occured!', variant: 'danger' })
            })
            .finally(() => setLoading(false))
    }

    const isActive = (isActive) => {
        return (
            <div style={{ border: "1px solid black", backgroundColor: 'rgba(146, 207, 213, 1)', borderRadius: '20px', width: 'fit-content', padding: '5px 30px', fontFamily: "Dimbo" }}  >{isActive ? "Active" : 'Deactivated'}</div>
        )
    }

    const oppAccept = (val) => {
        opp = false
        setShowPopup(false)
        console.log('accepted')

    }
    const oppSubscriptionCancel = (val) => {
        setShowPopup(false)
        setSubscribed(null)
        console.log('subscription cencel')
    }
    const onCancelSubcomfirmation = (val) => {
        setModelData({ title: 'Confirmation!', description: 'Are you sure you want to cancel this subscription?', onClick: oppSubscriptionCancel })
        setShowPopup(true)
    }

    const checkout = (props) => {
        if (props.success) {
            setSubscribed(selectedPlan)
            setAlert({ message: 'Payment successful.', variant: 'success' })
        } else {
            setAlert({ message: props?.msg || 'Payment failed.', variant: 'danger' })
        }
        setShowPaymentModel(false)

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)

    }

    useEffect(() => {

        if (opp) {
            setModelData({ title: "Opportunity for you!", description: 'Do you want to avail opportunity for 2 free months to stay a member?', onClick: oppAccept })
            setShowPopup(true)
        }

        if (planExpired) {
            setShowPlanExpiredModel(true)
        }

        if (subscribed == null || Object.keys(subscribed).length == 0) {
            setSubscribed(_subscribed)
        }

        if (userData) {
            let _userData = {}
            _userData['firstName'] = userData.first_name
            _userData['lastName'] = userData.last_name
            _userData['email'] = userData.email
        }
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
    }, [])

    return (
        <>
            <PlanExpired showPopup={showPlanExpiredModel} setShowPopup={setShowPlanExpiredModel} activate={activate} />
            <CustomModel showPopup={showPopup} setShowPopup={setShowPopup} title={modelData.title} description={modelData.description} onConfirm={modelData.onClick} />
            {showPaymentModel &&
                <Elements stripe={stripePromise} options={{ clientSecret: secret.clientSecret }}  >
                    {/* <Elements stripe={stripePromise}  > */}
                    <PaymentModel showPopup={showPaymentModel} setShowPopup={setShowPaymentModel} onConfirm={checkout} clientSecret={secret.clientSecret} />
                </Elements>}
            {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0 ,0 , 0.8)', zIndex: 110, color: 'white' }}  ><Spinner /></div>}
            <h1 style={{ fontFamily: 'Dimbo', fontSize: '35px', margin: "20px  0", textAlign: 'center' }}>Subscription Management</h1>
            {_alert?.message.trim() !== '' && <Alert variant={_alert.variant} dismissible onClose={() => setAlert({ message: '', variant: '' })} >{_alert.message}</Alert>}
            <div style={{ margin: "0 0 30px", textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <div className='' style={{ fontSize: '18px', fontWeight: '500', textAlign: 'end' }} >Pay Monthly</div>
                <div style={{ margin: '0 20px' }} ><Switch isOn={yearly} setSwitch={setMonthly} /></div>
                <div className='' style={{ fontSize: '18px', fontWeight: '500', textAlign: 'start' }} >Pay Yearly</div>
            </div>
            <div className='plans_container'>
                {plans.map((e, i) => {
                    return (
                        <div key={i} className='plan_container' >
                            <div className={`plan_container_main  ${(subscribed?.id == e.id) && " plan_container_main_margin"}  ${(!e.recommended) && "plan_container_main_margin_top"}`} style={{ backgroundColor: subscribed?.id == e.id ? 'rgba(146, 207, 213, 0.05)' : '#fff' }}>
                                {e.recommended &&
                                    <div style={{ backgroundColor: "rgb(146, 207, 213)", textAlign: 'center', fontWeight: '800', height: '35px', lineHeight: "35px", color: '#fff', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={require('../../assets/plans-star.png')} height={'20px'} alt='star recommended' style={{ objectFit: 'contain', marginRight: '5px' }} />
                                        Recommended
                                    </div>
                                }
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100% ', paddingBottom: e.recommended ? "35px" : '0' }}>
                                    <div style={{ padding: '30px 25px' }}  >
                                        <div style={{ fontSize: '20px', fontWeight: '800' }} >{e.name}</div>
                                        <div style={{ fontSize: '15px', color: "rgba(0 , 0 , 0 , 0.6)" }} >{e.description}</div>
                                        <div>
                                            <div style={{ margin: '15px 0' }} >
                                                <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{parseInt(e.price) == 0 ? "Free" : e.price}<span style={{ fontSize: '20px' }}>{parseInt(e.price) !== 0 && '/month'}</span></div>
                                                <div style={{ color: "rgba(0 , 0 , 0 , 0.6)", fontSize: '15px', lineHeight: '15px' }}  >{parseInt(e.price) !== 0 && 'Billed monthly after 14 day trial'}</div>
                                            </div>
                                            {e?.details && e.details.map((ele, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                                                        <span style={{ color: '#92cfd5', marginTop: 4 }} ><img src={require('../../assets/plans-check.png')} alt='plans check' /></span>
                                                        <span style={{ marginLeft: '10px', lineHeight: '20px', fontSize: '15px', margin: 7, color: "rgba(0 , 0 , 0 , 0.6)" }}>{ele}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ margin: 20 }} >
                                        <Buttons title={subscribed?.id == e.id ? "Current" : 'Activate'} onClick={() => subscribed?.id == e.id ? "" : activate(e)} variant='default' />
                                    </div>
                                </div>
                            </div>
                            {(subscribed?.id == e.id) && <div className='cancel_button'  >
                                <div style={{ width: 'fit-content', margin: 'auto' }} >
                                    <Buttons title={'Cancel subscription'} onClick={onCancelSubcomfirmation} variant='transparent' />
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>

            {history.length > 0 &&
                <>
                    <h1 className={`${subscribed ? "payment_history_margin" : "payment_history"}`}>Payment History</h1>
                    <div className='Payment_history' >
                        <table style={{ width: '100%', border: '1px solid black' }} >
                            <thead style={{ border: '1px solid black' }}>
                                <td>Date</td>
                                <td>Plan</td>
                                <td>Amount</td>
                                <td>Status</td>
                            </thead>
                            {history.map((e, i) =>
                                <tbody key={i} >
                                    <td>Date-{e.date}</td>
                                    <td>{e.plan}</td>
                                    <td>{e.amount}</td>
                                    <td>{isActive(e.status)}</td>
                                </tbody>
                            )}
                        </table>
                    </div>
                </>
            }
        </>
    )
}

export default Subscription