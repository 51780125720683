const initialState = {
    isLogged: false,
    data: null,
    accessToken: null,
    error: false,
    // counter: { }
};

export default (state = initialState, action) => {
    let data;//, counter;

    switch (action?.type) {

        case 'ERROR':
            return { ...state, error: action.payload };

        case 'VERIFY':
            return { ...state, ...action.payload };

        case 'LOGIN':

            localStorage.setItem('nestheadToken', action.payload?.accessToken);

            data = {
                isLogged: true,
                data: action.payload?.user,
                accessToken: action.payload?.accessToken,
            };

            return { ...state, ...data };

        case 'UPDATE':
            data = { ...state.data, ...action.payload };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        case 'UPDATE_PROFILE':
            // localStorage.setItem('nestheadToken',action.payload?.accessToken);

            data = {
                // isLogged: true,
                data: action.payload?.user,
                // accessToken: action.payload?.accessToken,
            };

            return { ...state, ...data };
        case "UPDATE_IMAGE":
            data = {
                // isLogged: true,
                data: { ...state.data, photo: action.payload }
                // accessToken: action.payload?.accessToken,
            };

            return { ...state, ...data };

        case 'UPDATE_EXTRA':
            let extra = { ...state.data?.extra, ...action.payload };
            data = { ...state.data, extra: extra };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        case 'UPDATE_SETTINGS':
            let settings = { ...state.data?.settings, ...action.payload };
            data = { ...state.data, settings: settings };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        //case 'COUNTER':
        //  counter = { ...state.counter, ...action.payload };
        //return { ...state, counter: counter };

        case 'LOGOUT':

            data = {
                isLogged: false,
                user: null,
                error: false,
                counter: {},
            }

            localStorage.removeItem('nestheadToken');

            return { ...state, ...data };

        default:
            return state;
    }
};