import React, { useState, useEffect } from "react";
import '../App.css';
import logo from '../assets/logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../services/Api";
import { logout } from "../actions/userAction";
import { Dropdown } from "react-bootstrap";

let defaultimg = require('../assets/user.png')

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const [active, setActive] = useState('login');

    const logOut = () => {
        Api.get('auth/logout').then(res => {
            if (res) {
                dispatch(logout());
                navigate('/login')
            }
        });
    }

    const navigateto = (route) => {
        navigate('/' + route)
        setActive(route)
    }

    let location = useLocation()

    useEffect(() => {
        let url = location.pathname.slice(1, location.pathname.length)
        // let splice = _url.split("/")
        // let url =splice[0]
        // console.log(splice[0])
        if (url == 'register') {
            setActive(url)
        } else {
            setActive("login")
        }
    }, [location])

    return (
        <div className="logo">
            <img className="logo-img" onClick={() => navigate(user.isLogged ? '/' : '/login')} style={{ cursor: 'pointer' }} src={logo} width={180} height={50} alt="Nestheads logo" />
            {user?.isLogged ?
                <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                    <Dropdown>
                        <Dropdown.Toggle style={{ backgroundColor: 'transparent', color: 'black', borderColor: 'lightgray', borderRadius: '30px', margin: '0px', padding: '3px 20px' }}>
                            <div style={{ display: 'inline', fontFamily: 'Dimbo' }} >{user?.data?.first_name || ""} </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate('./settings')}>Account</Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div style={{ width: 40, height: 40, borderRadius: '50%', overflow: 'hidden', marginLeft: '10px' }} >
                        <img onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }} src={user?.data?.photo ? user.data.photo : defaultimg} width='100%' height='100%' alt="Profile picture" />
                    </div>
                </div>
                :
                <div className="header-right" style={{ display: 'flex', alignItems: 'center', }}>
                    <div className={active === 'login' ? 'active' : 'header-right-btn'} onClick={() => navigateto('login')}>Sign In</div>
                    <div className={active === 'register' ? 'active' : 'header-right-btn'} onClick={() => navigateto('register')}>Sign Up</div>
                </div>
            }
        </div>
    )
}

export default Header;