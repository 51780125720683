import React, { useEffect, useState } from 'react'
import { useAsyncError, useNavigate } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import Api from '../services/Api';
import { Helmet } from 'react-helmet';

const ChatComponent = (props) => {

    const { chat_id = 0, character_id = null } = props;
    const [showAbout, setShowAbout] = useState(false);
    const [notAvilable, setNotAvilable] = useState(false);
    const [modelNotAvilable, setModelNotAvilable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [character, setCharacter] = useState(null);
    const [message, setMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [showField, setShowField] = useState(true);
    const [sending, setSending] = useState(false);
    const [chatID, setChatID] = useState(0);

    const navigate = useNavigate();

    const send = (e) => {
        e.preventDefault();

        if (message.trim() == "") {
            return
        }
        let _height = document.querySelector('.start_chat')?.offsetHeight || 0;
        let obj = {
            dataTime: new Date().getTime(),
            message: message,
            by: 'U'
        };

        let Chatobj = {
            dataTime: new Date().getTime(),
            message:
                "Typing...",
            by: 'C'
        };

        setChatMessages(prev => [...prev, obj, Chatobj]);

        setTimeout(() => {
            window.scrollTo(0, _height);
        }, 10);

        setSending(true)
        Api.post('chat/sendMessage', {
            "chat_id": chatID,
            "character_id": character_id,
            "message": message
        })
            .then((resData) => {
                // console.log(resData);
                if (resData.success) {
                    if (resData.data.list.allow_verify) {
                        setModelNotAvilable(false);
                    } else {
                        setModelNotAvilable(true);
                    }
                    let res = resData.data.list.message;
                    let msg = res.split(' ');
                    let _text = '';
                    let end = msg.length;
                    let reached = 0;
                    // setChatId(resData.data.list.chat_id)
                    setChatID(resData.data.list.chat_id)
                    let interval = setInterval(() => {
                        if (reached == end) {
                            clearInterval(interval)
                        } else {
                            _text = _text + msg[reached] + " ";
                            reached++
                            let obj = {
                                dataTime: new Date().getTime(),
                                message: _text,
                                by: "C"
                            }
                            setChatMessages(prev => {
                                let _prev = prev
                                _prev[prev.length - 1] = obj
                                return [..._prev]
                            })
                        }
                        window.scrollTo(0, _height);
                    }, 10)
                } else {
                    // console.log(resData)
                    let obj = {
                        dataTime: new Date().getTime(),
                        message: "Some error occurred!",
                        by: "C"
                    }

                    setChatMessages(prev => {
                        let _prev = prev
                        _prev[prev.length - 1] = obj
                        return [..._prev]
                    })
                }
            })
            .catch((err) => {
                console.log(err)
                let obj = {
                    dataTime: new Date().getTime(),
                    message: "Some error occurred Please try again!",
                    by: "C"
                }

                setChatMessages(prev => {
                    let _prev = prev
                    _prev[prev.length - 1] = obj
                    return [..._prev]
                })
            })
            .finally(() => {
                setSending(false)
            })
        setMessage('')
    }

    useEffect(() => {
        // console.log(character_id , chat_id)
        if (character_id == null) {
            setShowField(false)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 3000)
        } else {
            setChatID(chat_id)
            setChatMessages([])
            setLoading(true)
            Api.post(`characters/detail/${parseInt(character_id)}`)
                .then((res) => {
                    // console.log(res)
                    if (res.success) {
                        setCharacter(res.data);
                        if (res.data.status == null) {
                            setModelNotAvilable(true);
                            setShowField(false)
                        } else {
                            setModelNotAvilable(false);
                            setShowField(true)
                        }
                    } else {
                        // navigate('/models');
                        setCharacter(null);
                        setModelNotAvilable(true);
                        setShowField(false)
                    }
                })
                .catch(() => {
                    setCharacter(null)
                    setModelNotAvilable(true);
                    setShowField(false)
                }).finally(() => {
                    if (!chat_id) {
                        setLoading(false)
                    }
                })
            if (chat_id) {
                // alert(JSON.stringify(`chat/${character_id}/${chat_id}`))
                setLoading(true)
                setShowField(false)

                Api.post(`chat/detail`,
                    {
                        "chat_id": chat_id,
                        "character_id": JSON.stringify(parseInt(character_id))
                    })
                    .then((res) => {
                        // console.log(res.data)
                        if (res.success && res.data != null) {
                            setChatMessages(res.data)
                            setNotAvilable(false);
                            setShowField(true)

                        } else {
                            setNotAvilable(true);
                            setChatMessages([]);
                            setShowField(false)
                        }
                    })
                    .catch(() => {
                        setChatMessages([])
                        setShowField(false)

                    }).finally(() => {
                        setLoading(false);
                    })
            }
        }
    }, [chat_id, character_id])
    // console.log(character_id)

    useEffect(() => {
        if (!loading) {
            let _height = document.querySelector('.start_chat')?.offsetHeight || 0;
            window.scrollTo(0, _height);
        }
    }, [chatMessages])

    if (loading) {
        return (
            <>
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', width: '100%', margin: '5px 0px' }} ></div>
            </>
        )
    }

    return (
        <>
            <div className='start_chat'>
                <Helmet>
                    <title>{character?.seo || ""}</title>
                    <meta property='description' content={character?.description || ""} />

                    <meta property="og:url" content="https://www.nestheads.ai/chat" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={character?.seo || "model"} />
                    <meta property="og:description" content={character?.description || ""} />
                    <link rel="canonical" href={character?.image} />
                    <meta property="og:image" content={character?.image || ''} />
                    <meta property="og:image:secure_url" content={character?.image || ''} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="nestheads.ai" />
                    <meta property="twitter:url" content="https://www.nestheads.ai/chat" />
                    <meta name="twitter:title" content={character?.seo || ''} />
                    <meta name="twitter:description" content={character?.description || ''} />
                    <link rel="canonical" href={character?.image} />
                    <meta name="twitter:image" content={character?.image || ""} />
                </Helmet>
                <div className='main_character_container'>
                    <div className='chat_img_container'>
                        {character ?
                            <>
                                <div className={`${showAbout ? 'about_character_active' : "about_character"}`}> Click on Character to learn more.</div>
                                {(chatMessages == null || chatMessages?.length == 0) ?
                                    <a href={`/models/${character?.seo}`} onClick={e => e.preventDefault()}>
                                        <img src={character?.image || ''} alt={character?.name} style={{ height: '250px', margin: 'auto' }} onMouseEnter={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} onClick={() => navigate(`/models/${character?.seo}`)} />
                                    </a>
                                    :
                                    <a href={`/models/${character?.seo}`} onClick={e => e.preventDefault()} >
                                        <img src={character?.image || ''} alt={character?.name} style={{ maxWidth: "170px", height: '170px', margin: 'auto' }} onMouseEnter={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} onClick={() => navigate(`/models/${character?.seo}`)} />
                                    </a>
                                }
                            </>
                            :
                            <div>
                                <div style={{ width: '300px', backgroundColor: 'rgb(250 , 250, 250)', borderRadius: 150, height: '300px', margin: '0 auto' }}  ></div>
                            </div>
                        }
                    </div >
                    <div className={`${chatMessages?.length > 0 ? "starting_box_small" : 'starting_box'}`}>
                        <div style={{ color: character?.color || 'black' }}>{character?.name || "Character"}:</div>
                        <div>Hey there! What’s on your mind?</div>
                    </div>
                </div>
                <div className='chat_messages_container'>
                    {modelNotAvilable
                        ?
                        chatMessages && chatMessages.map((e, i) => {
                            return (
                                <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <ChatMessage msgdata={e} character={character} />
                                </div>
                            )
                        })
                        :
                        notAvilable ?
                            <div style={{ fontFamily: 'Dimbo', fontSize: '20px' }}>Chat not Available</div>
                            :
                            chatMessages && chatMessages.map((e, i) => {
                                return (
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <ChatMessage msgdata={e} character={character} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div >
            {modelNotAvilable &&
                <div style={{ fontFamily: 'Dimbo', fontSize: '22px', textAlign: 'center', marginBottom: '50px' }} >Model not Available!</div>
            }
            {
                showField &&
                <form onSubmit={sending ? (e) => { e.preventDefault() } : send} >
                    <div className='chat_field'>
                        <input placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} />
                        <div className='chat_btn' onClick={sending ? (e) => { e.preventDefault() } : send}  ><img src={require('../assets/arrow_up.png')} alt='Send button'/></div>
                    </div>
                </form>
            }

        </>
    )
}

export default ChatComponent