import React, { useEffect, useRef, useState } from 'react'
import InputField from '../../components/InputField'
import { useSelector } from 'react-redux'
import Api from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Button from '../../components/Button';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { login, updateProfile, updateImage } from '../../actions/userAction';
import axios from 'axios';
import Config from '../../config.json';

const Account = () => {

    const upload = useRef()

    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')
    const [photo, setPhoto] = useState('')
    const [loading, setLoading] = useState(false);
    const [_alert, setAlert] = useState({});
    const [errors, setErrors] = useState({})
    const [image, setImage] = useState(null)
    const [resetProcess, setResetProcess] = useState(false)
    const [pictureloading, setPictureLoading] = useState(false)

    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {

        setFirstName(user.data.first_name)
        setLastName(user.data.last_name)
        setEmail(user.data.email)
        setRole(user.data.role)
        setPhoto(user.data.photo)
    }, [user])


    useEffect(() => {
        setAlert({})
        setErrors({})
    }, [first_name, last_name, email])


    const checkValidate = () => {

        let error = {}

        if (first_name.trim() == "") {
            error.firstName = "FirstName is required!"
        } else if (/\d/.test(first_name)) {
            error.firstName = "FirstName is not valid!"
        }
        if (last_name.trim() == "") {
            error.lastName = "LastName is required!"
        } else if (/\d/.test(last_name)) {
            error.lastName = "Lastname is not valid!"
        }
        if (email.trim() == "") {
            error.email = "Email is required!"
        } else {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let valid = reg.test(email)

            if (!valid) {
                error.email = "Enter Valid Email!"
            }
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }


    const updateProfileData = () => {
        setErrors({})
        setAlert({})
        let validate = checkValidate();

        if (validate) {
            setLoading(true)
            Api.post('user/update/account', { first_name, last_name, email }).then((res) => {
                if (res.success) {
                    dispatch(updateProfile({ user: res.data.user }))
                    setAlert({ variant: 'success', message: "Profile updated successfully!" });
                } else {
                    if (res.message == 'validtion-errors') {
                        setAlert({ variant: 'danger', message: "Email Already in use!" })
                    }
                    else {
                        setAlert({ variant: 'danger', message: res.message || "Some error occured!" })
                    }
                }

            }).catch((error) => {
                setAlert({ variant: 'danger', message: error.message || "Some error occured!" })

            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const reset = () => {
        setAlert({})
        setResetProcess(true)
        Api.post('user/rest/photo').then((res) => {
            if (res.success) {
                dispatch(updateImage(null))
                setAlert({ variant: 'success', message: "Profile picture updated successfully!" })
            } else {
                setAlert({ variant: 'danger', message: "Profile picture updated failed!" })
            }
        }).catch((err) => {
            setAlert({ variant: 'danger', message: err?.message || "Some Error occured please try again!" })

        }).finally(() => {
            setResetProcess(false)
        })
    }

    const uploadimage = (e) => {
        setAlert({})
        setPictureLoading(false)

        const url = Config.apiUrl + "user/update/photo";
        let file = e.target.files[0]
        let fileSize = file.size / 1024

        if (fileSize.toFixed() <= 800) {

            let token = localStorage.getItem('nestheadToken')
            const formData = new FormData();
            formData.append('photo', file);
            const config = {
                headers: {
                    'Content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token,
                }
            };

            setPictureLoading(true)
            axios.post(url, formData, config).then((response) => {
                if (response.data.success) {
                    setAlert({ variant: 'success', message: "Profile picture updated successfully!" })
                    dispatch(updateProfile(response.data.data))
                } else {
                    setAlert({ variant: 'danger', message: "Profile picture updated failed!" })
                }
            }).finally(() => {
                setPictureLoading(false)
            })
        } else {
            setAlert({ variant: 'danger', message: "Image size too Big!" })
        }
    }

    return (
        <div>
            {/* <div>{JSON.stringify(user.data)}</div> */}
            {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
            <div className='settings-header' >
                <div className='settings-profile-pic' >
                    <img src={photo ? photo : require('../../assets/user.png')} width='100%' height='100%' alt='Profile picture' />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: '7px 0px' }} >
                    <div id='upload' className='picture-btn' >
                        <div style={{ position: 'relative', backgroundColor: 'black', borderRadius: '30px', color: 'white', padding: '5px 30px', overflow: 'hidden', fontFamily: 'Dimbo' }} >
                            {pictureloading ? <Spinner size='sm' /> : "Upload new Picture"}
                            <input onChange={(e) => uploadimage(e)} type="file" accept="image/gif, image/jpeg, image/png" style={{ position: 'absolute', top: 0, zIndex: 10, left: 0, cursor: 'pointer' }} />
                        </div>
                        <div onClick={photo ? reset : () => { }} className='picture-btn-reset' >{resetProcess ? <Spinner size='sm' /> : "Reset"}</div>
                    </div>
                    <div style={{ fontSize: '12px', color: "rgba(58, 53, 65, 0.68)" }} >
                        Allowed JPG, GIF or PNG. Max size of 800K
                    </div>
                </div>
            </div>
            <div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={first_name} placeholder='First name' type="text" onChange={setFirstName} lable='First name' />
                        {errors?.firstName && <div className="required-field" >{errors?.firstName}</div>}
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={last_name} placeholder='Last name' type="text" onChange={setLastName} lable='Last name' />
                        {errors?.lastName && <div className="required-field" >{errors?.lastName}</div>}
                    </div>
                </div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={email} placeholder='Email Address' type="email" onChange={setEmail} lable='Email' />
                        {errors?.email && <div className="required-field" >{errors?.email}</div>}

                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField disabled={true} value={role ? "User" : 'Admin'} placeholder='Role' type="text" onChange={() => { }} lable='Role' />
                    </div>
                </div>
                <div className='settings-bottom-btn' >
                    {/* <Button loading={loading} name={'Save Changes'} variant={"black"} onChange={updateProfile} /> */}
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: "Dimbo" }} >
                        <div onClick={updateProfileData} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}> {loading ? <Spinner size='sm' /> : "Save Changes"}</div>
                    </div>
                    <div className='settings-btn-cancle' >
                        <div onClick={() => navigate('/')} style={{ padding: '10px 30px', borderRadius: '25px', border: '2px solid black', width: 'fit-content', width: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer', fontFamily: "Dimbo" }}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account