import React, { useState, useEffect, useRef } from 'react'
import Buttons from './Buttons';

const plans = [
    {
        id: 1,
        name: 'Basic',
        price: "0",
        serviceCharge: '3',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        details: [
            "5 Chats",
            "1 Free Nestheads Finder Report",
            "Unlimited Words in Chat"
        ]
    },
    {
        id: 2,
        name: 'Premium',
        price: "44469",
        serviceCharge: '3',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        active: true,
        recommended: true,
        details: [
            "Everything in Basic +",
            "300 Chats",
            "Unlimited Words in Chat",
        ]
    },
    {
        id: 4,
        name: 'Epic',
        price: "44469",
        serviceCharge: '3',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        details: [
            "Everything in Basic +",
            "Everything in Basic +",
            "300 Chats",
            "300 Chats",
            "300 Chats",
            "Unlimited Words in Chat",
        ]
    },
]

let _subscribed = {
    id: 2,
    name: 'Premium',
    price: "9.99",
    serviceCharge: '3',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    active: true,
    details: [
        "Everything in Basic +",
        "300 Chats",
        "Unlimited Words in Chat",
    ]
}


const PlanExpired = (props) => {

    const { showPopup, setShowPopup, onConfirm  , activate} = props
    const [subscribed, setSubscribed] = useState({})
    const [loading, setLoading] = useState(false)

    const popupRef = useRef(null);
    const contentref = useRef(null)



    useEffect(() => {

        if (subscribed == null || Object.keys(subscribed).length == 0) {
            setSubscribed(_subscribed)
        }
        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div ref={contentref} style={{ padding: "25px", borderRadius: '10px', backgroundColor: 'white', top: 0, left: 0, bottom: 0, right: 0, width: '80%', maxHeight: '90vh', overflowY: 'scroll' }} >
                        <div style={{ margin: 'auto', textAlign: 'center', marginBottom: '10px' }} >
                            <div style={{ fontSize: '30px', fontFamily: 'Dimbo', }} >{'You are a Self-Discovery Super Star!!!'}</div>
                            <div style={{ color: "rgba(0 , 0 , 0 , 0.6)", }} >{'Unfortunately, you hit your subscription tier limit, upgrade here or purchase additional credits below  '}</div>
                        </div>
                        <div className='plan_expired'>
                            {plans.map((e, i) => {
                                return (
                                    <div key={i} style={{ borderRadius: '10px', border: '1px solid rgb(146, 207, 213)', backgroundColor: e.recommended ? 'rgba(146, 207, 213, 0.05)' : '#fff', overflow: 'hidden', marginTop: !e.recommended ? '35px' : '0', marginBottom: '10px' }}>
                                        {e.recommended && <div style={{ backgroundColor: "rgb(146, 207, 213)", textAlign: 'center', fontWeight: '800', height: '35px', lineHeight: "35px", color: '#fff', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <img src={require('../assets/plans-star.png')} height={'20px'} alt='star recommended' style={{ objectFit: 'contain', marginRight: '5px' }} /> Recommended</div>}
                                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100% ', paddingBottom: e.recommended ? "35px" : '0' }}>
                                            <div style={{ padding: '20px 25px' }}  >
                                                <div style={{ fontSize: '20px', fontWeight: '800' }} >{e.name}</div>
                                                <div style={{ fontSize: '15px', color: "rgba(0 , 0 , 0 , 0.6)" }} >{e.description}</div>
                                                <div>
                                                    <div style={{ margin: '15px 0' }} >
                                                        <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{parseInt(e.price) == 0 ? "Free" : e.price}<span style={{ fontSize: '20px' }}>{parseInt(e.price) !== 0 && '/month'}</span></div>
                                                        <div style={{ color: "rgba(0 , 0 , 0 , 0.6)", fontSize: '15px', lineHeight: '15px' }}  >{parseInt(e.price) !== 0 && 'Billed monthly after 14 day trial'}</div>
                                                    </div>
                                                    {e?.details && e.details.map((ele, i) => {
                                                        return (
                                                            <div key={i} style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                                                                <span style={{ color: '#92cfd5', marginTop: 2 }} ><img src={require('../assets/plans-check.png')} alt='plans check' /></span>
                                                                <span style={{ marginLeft: '10px', lineHeight: '20px', fontSize: '15px', margin: 5, color: "rgba(0 , 0 , 0 , 0.6)" }}>{ele}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div style={{ margin: "0 10px 10px" }} >
                                                <Buttons title={'Activate'} onClick={() => activate(e)} variant='default' loading={loading} />
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default PlanExpired