import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Chat from '../screens/Chat';
import Insights from '../screens/Insights';
import Models from '../screens/Models';
import Settings from '../screens/Settings';
import Login from '../screens/Login';
import Register from '../screens/Register';
import ForgotPassword from '../screens/ForgotPassword';
import Code from '../screens/Code';
import Api from '../services/Api';
import { login } from '../actions/userAction';
import { Spinner } from 'react-bootstrap';
import CompleteProfile from '../screens/CompleteProfile';
import LandingPage from '../screens/LandingPage';
import { useLocation } from "react-router-dom";
import Pagenotfound from "./Pagenotfound";
import HomePage from "./HomePage";
import { SubscriptionForAll } from "./SubscriptionForAll";

const Naviagation = () => {
    const user = useSelector((state) => state?.user)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const [waitList, setWaitList] = useState(true)
    const [modelsDetails, setModelsDetails] = useState(false)

    const AI = true

    const isLogged = () => {
        return localStorage.getItem('nestheadToken') || false;
    }

    useEffect(() => {
        let log = isLogged()
        if (log) {
            setLoading(true)
            Api.post('user/profile').then(res => {
                if (res.success) {
                    dispatch(login({ user: res.data.user, accessToken: log }));
                    Api.setToken(log);
                } else {
                    localStorage.removeItem('nestheadToken')
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        let path = location.pathname
        // console.log(path)
        if (['/login', '/waitlist', '/', '/register'].includes(path)) {
            setWaitList(true)
        } else {
            setWaitList(false)
        }

        if (path.includes('/models')) {
            setModelsDetails(true)
        } else {
            setModelsDetails(false)
        }
    }, [location])

    return (
        <>
            {loading ?
                <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Spinner />
                </div>
                :
                <>
                    {!AI &&
                        <div style={{ position: 'fixed', top: 0, left: 0, width: "100%", zIndex: 101 }} >
                            <Header />
                        </div>
                    }
                    {modelsDetails &&
                        <div style={{ position: 'fixed', top: 50, left: 0, width: "100%", zIndex: 100 }} >
                            <Navbar />
                        </div>
                    }

                    {!waitList && AI &&
                        <>
                            <div style={{ position: 'fixed', top: 0, left: 0, width: "100%", zIndex: 101 }} >
                                <Header />
                            </div>
                            <div style={{ position: 'fixed', top: 50, left: 0, width: "100%", zIndex: 100 }} >
                                <Navbar />
                            </div>
                        </>
                    }

                    {user?.isLogged ?
                        <>
                            {!AI && <>
                                <div style={{ position: 'fixed', top: 50, left: 0, width: "100%", zIndex: 100 }} >
                                    <Navbar />
                                </div>
                            </>}
                            <Routes>
                                {AI && <Route path='/' element={<LandingPage />}></Route>}
                                {AI && <Route path='/login' element={<LandingPage />} ></Route>}
                                {AI && <Route path='/waitlist' element={<LandingPage />}></Route>}
                                {!AI && <Route path='/' element={<HomePage />}></Route>}
                                <Route path='/chat/:id?' element={<Chat type="1" />}></Route>
                                <Route path='/chat/:id/:chat_id?' element={<Chat type="2" />}></Route>
                                <Route path='/insights' element={<Insights />}></Route>
                                {/* <Route path='/subscription' element={<SubscriptionForAll />}></Route> */}
                                <Route path='/models/:id?' element={<Models />}></Route>
                                <Route path='/settings/:id?' element={<Settings />}></Route>
                                <Route path='/*' element={<Pagenotfound />}></Route>
                            </Routes>
                        </>
                        :
                        <Routes>
                            {/* <Route path='/login' element={<Login />}></Route> */}
                            <Route path='/' element={AI ? <LandingPage /> : <Login />}></Route>
                            <Route path='/login' element={AI ? <LandingPage /> : <Login />}></Route>
                            <Route path='/waitlist' element={AI ? <LandingPage /> : <Login />}></Route>
                            {/* <Route path='/subscription' element={<SubscriptionForAll />}></Route> */}
                            <Route path='/register' element={AI ? <LandingPage /> : <Register />}></Route>
                            <Route path='/models/:id?' element={<Models />}></Route>
                            <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                            <Route path='/code' element={<Code />}></Route>
                            <Route path='/complete-profile' element={<CompleteProfile />}></Route>
                            <Route path='/*' element={<Pagenotfound />}></Route>

                        </Routes>
                    }
                </>
            }
        </>
    );
}

export default Naviagation