import React, { useEffect, useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Alert } from 'react-bootstrap'
import { Unlock } from 'react-bootstrap-icons'

const visa = require('../assets/card_icons/visa.png')
const amex = require('../assets/card_icons/amex.png')
const american_express = require('../assets/card_icons/american_express.png')
const diners = require('../assets/card_icons/diners.png')
const discover = require('../assets/card_icons/discover.png')
const elo = require('../assets/card_icons/elo.png')
const jcb = require('../assets/card_icons/jcb.png')
const mastercard = require('../assets/card_icons/mastercard.png')
const unionpay = require('../assets/card_icons/unionpay.png')
const unknown = require('../assets/card_icons/unknown.png')

const PaymentForm = (props) => {

    const { validated, onSuccess, setToken, setValidated, setLoading } = props

    const [validate, setValidate] = useState(false)
    const [_alert, setAlert] = useState({})
    const [card, setCard] = useState(unknown)

    let stripe = useStripe();
    let elements = useElements();

    const updateCard = (event) => {
        console.log(event.brand)
        if (event?.brand) {
            let card = event.brand
            let _card = card == "visa" ? visa : card == 'amex' ? amex : card == 'american_express' ? american_express : card == "diners" ? diners : card == "discover" ? discover : card == 'jcb' ? jcb : card == 'mastercard' ? mastercard : card == 'unionpay' ? unionpay : unknown;
            setCard(_card)
        }
    }

    function stripeSuccess(result) {

    }

    function stripeError(error = false) {
    }

    function stripeResponse(result, type) {


        if (result.error)
            stripeError(result.error.message);
        else {
            if (type == 'submit') {
                if (result.token) {
                    let card = result.token.card;
                    let resp = {
                        token_id: result.token.id,
                        type: 'card',
                        id: card.id,
                        info: {
                            brand: card.brand.toLowerCase(),
                            number: card.last4,
                            expiry: card.exp_month + "/" + card.exp_year,
                        }
                    }
                    stripeSuccess(resp);
                } else
                    stripeError("There are problem to save card, try again");
            }
        }
    }

    const generateToken = (type) => {
        console.log('generating token')

        let cardNumberElement = elements.getElement(CardNumberElement)
        // console.log(cardNumberElement)
        try {
            let params = { address_zip: props.address_zip, name: props.name }
            setLoading(true);
            stripe.createToken(cardNumberElement, params)
                .then((event) => {
                    if (event?.error) {
                        if (event.error.type = 'validation_error') {
                            if (type == "submit") {
                                setAlert({ msg: 'Please enter valid card details!', variant: 'danger' })
                            }
                        }
                    } else {
                        if (type == "submit") {
                            setToken(event)
                        }
                    }
                })
                .catch((er) => {
                    console.log(er)
                })
                .finally(() => {
                    setLoading(false)
                })
        } catch (e) {
            // setProcessing(false);
        }
    }

    useEffect(() => {
        if (validate) {
            generateToken('submit')
            setValidate(false)
            setValidated(false)
        }
    }, [validate])

    useEffect(() => {
        setAlert({})
        setValidate(validated)
    }, [validated])


    return (
        <>
            <div style={{ fontFamily: 'Dimbo', fontSize: 22 }} >Payment Information</div>
            <div style={{ width: '100%', padding: ' 0px 10px', marginTop: '20px' }} >
                {_alert.msg && <Alert dismissible variant={_alert.variant} onClose={() => setAlert({})} >
                    {_alert.msg}
                </Alert>}
            </div>
            <div className='settings-forms' >
                <div style={{ padding: ' 0px 10px', width: '100%' }} >
                    <div className='cardfields' >
                        <div className='cardinputlabel' >Card no</div>
                        <CardNumberElement id='card-nr' className='cardinput-field' onChange={(e) => { generateToken(); updateCard(e) }} />
                        <img style={{ position: 'absolute', right: 10, top: 9 }} width={50} src={card} />
                    </div>
                </div>
            </div>
            <div className='settings-forms' >
                <div style={{ padding: '0px 10px', width: '100%' }} >
                    <div className='cardfields' >
                        <div className='cardinputlabel' >Expiry date</div>
                        <CardExpiryElement id='card-exp' className='cardinput-field' onChange={generateToken} />
                    </div>
                </div>
                <div style={{ padding: '0px 10px', width: '100%' }} >
                    <div className='cardfields' >
                        <div className='cardinputlabel' >CVC</div>
                        <CardCvcElement id='card-cvc' className='cardinput-field' onChange={generateToken} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentForm