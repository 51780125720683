import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBDlmmzvmYkQIP0UOz4NSDw__3_wxR2qns",
    authDomain: "nestheads-97e10.firebaseapp.com",
    projectId: "nestheads-97e10",
    storageBucket: "nestheads-97e10.appspot.com",
    messagingSenderId: "367467737562",
    appId: "1:367467737562:web:78cb1f2519e2d540ab50e3"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();

export { auth, firebase };