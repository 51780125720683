import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const [select, setSelect] = useState('');
    const location = useLocation();

    const isLogged = useSelector(state => state.user.isLogged)

    useEffect(() => {
        let _url = location.pathname.slice(1, location.pathname.length)
        let splice = _url.split("/")
        let url = splice[0]

        if (url) {
            if (url == 'login') {
                setSelect('')
            } else {
                setSelect(url)
            }
        } else {
            setSelect("")
        }
    }, [location])

    return (
        <nav className="nav">
            <Link className={`${select === 'chat' ? 'navi-link-active' : "navi-link"}`} to={isLogged ? "/chat" : "/login"} onClick={() => setSelect('chat')}>Chat</Link>
            <Link className={`${select === 'insights' ? 'navi-link-active' : "navi-link"}`} to={isLogged ? "/insights" : "/login"} onClick={() => setSelect('insights')}>Insights</Link>
            <Link className={`${select === 'models' ? 'navi-link-active' : "navi-link"}`} to={"/models"} onClick={() => setSelect('models')}>Models</Link>
            <Link className={`${select === 'settings' ? 'navi-link-active' : "navi-link"}`} to={isLogged ? "/settings" : "/login"} onClick={() => setSelect('settings')}>Settings</Link>
            {/* {isLogged && <Link className={`${select === 'subscription' ? 'navi-link-active' : "navi-link"}`} to={"/subscription"} onClick={() => setSelect('subscription')}>Subscription</Link>} */}
        </nav>
    )
}

export default Navbar