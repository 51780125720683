import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const defaultUserImg = require('../assets/user.png');

const ChatMessage = (props) => {

    const user = useSelector(state => state.user);
    const { msgdata, character } = props;
    const [userData, setUserData] = useState({});

    useEffect(() => {
        setUserData(user.data);
    }, [user])

    return (
        <div className='msg_container' style={{ alignSelf: msgdata.by == "C" ? "flex-start" : 'flex-end' }} >
            {msgdata.by == "C" && <img src={character?.image || defaultUserImg} height={35} style={{ marginTop: 5, borderRadius: "50%" }} alt='model picture' />}
            <div style={{ margin: "0px 10px", backgroundColor: msgdata.by == "C" ? 'rgba(232, 236, 239, 0.4)' : 'rgba(146, 207, 213, 0.3)', padding: '10px 15px', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px' }}>{msgdata.message}</div>
            {msgdata.by != "C" && <img src={userData?.photo || defaultUserImg} height={35} style={{ marginTop: 5, borderRadius: "50%" }} alt='Character picture' />}
        </div>
    )
}

export default ChatMessage