import React, { useState, useEffect, useRef } from 'react'
import Buttons from './Buttons';
import { useElements, useStripe, PaymentElement, ExpressCheckoutElement } from '@stripe/react-stripe-js'

const PaymentModel = (props) => {

    const { showPopup, setShowPopup, onConfirm, clientSecret } = props

    const popupRef = useRef(null);
    const contentref = useRef(null)

    const [loading, setLoading] = useState(false)

    let stripe = useStripe();
    let elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true)

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: "https://google.com",
                },
                redirect: "if_required",
            });

            if (error) {
                if (error.type == 'validation_error') {
                    setLoading(false)
                    return
                }
                onConfirm({ success: false, msg: error.message })

            } else if (paymentIntent && paymentIntent.status === "succeeded") {
                onConfirm({ success: true, msg: "Payment successfull", data: paymentIntent })
            } else {
                onConfirm({ success: false, msg: error?.message || "Internal error occured." })
            }
            setLoading(false)
        } catch (error) {
            onConfirm({ success: false, msg: error?.message || "Internal error occured." })
        }
    };

    // useEffect(() => {

    //     if (stripe != null) {

    //         const appearance = {
    //             // theme: 'flat',
    //             // variables: { colorPrimaryText: '#262626' }
    //         };

    //         const expressCheckoutOptions = {
    //             layout: {
    //                 type: 'tabs',
    //                 defaultCollapsed: false,
    //             },
    //             buttonType: {
    //                 applePay: 'buy',
    //                 googlePay: 'buy',
    //                 paypal: 'buynow'
    //             }
    //         }
    //         const elements = stripe.elements({
    //             clientSecret, appearance
    //         })
    //         const expressCheckoutElement = elements.create(
    //             'payment',
    //             expressCheckoutOptions
    //         )

    //         expressCheckoutElement.on('confirm', handleSubmit)
    //         expressCheckoutElement.mount('#express-checkout-element')
    //     }
    // }, [stripe])

    // const validate = async () => {

    //     const { error, paymentIntent } = await stripe.confirmPayment({
    //         elements,
    //         confirmParams: {
    //             return_url: "https://google.com",
    //         },
    //         redirect: "if_required",
    //     });

    //     if (error) {
    //         setDisable(true)
    //     } else {
    //         setDisable(false)
    //     }
    // }


    useEffect(() => {

        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div ref={contentref} className='payment_model' >
                        <form onSubmit={handleSubmit} >
                            <div style={{ fontSize: '30px', fontFamily: 'Dimbo' }} >Payment Details</div>
                            {/* <div id='express-checkout-element'></div> */}
                            <PaymentElement />
                            {/* <ExpressCheckoutElement onConfirm={handleSubmit} /> */}
                            <div className='mt-4'>
                                <Buttons disabled={!stripe} title={'Submit'} onClick={handleSubmit} loading={loading} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div >
    )
}

export default PaymentModel