import React from 'react'

const Switch = (props) => {

    const { isOn, setSwitch } = props

    return (
        <div className='switch_outer'>
            <div className={`switch_inner ${isOn == null ? "" : isOn ? "switch_on" : 'switch_off'}`} onClick={() => setSwitch(!isOn)} ></div>
        </div>
    )
}

export default Switch