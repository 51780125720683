import React, { useState, useEffect, useRef } from 'react'
import Buttons from './Buttons';

const CustomModel = (props) => {

    const { showPopup, setShowPopup, title = "Title", description = "Description", onConfirm } = props

    const popupRef = useRef(null);
    const contentref = useRef(null)


    useEffect(() => {


        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div ref={contentref} className='custom_model' >
                        <div style={{ fontSize: '40px', fontFamily: 'Dimbo', }} >{title}</div>
                        <div>{description}</div>
                        <div style={{ marginTop: '30px', width: "100%", display: 'flex', alignItems: 'center' }} >
                            <div style={{ flex: 1, margin: '0 5px' }}>
                                <Buttons title={'No'} variant='transparent' onClick={() => setShowPopup(false)} />
                            </div>
                            <div style={{ flex: 1, margin: '0 5px' }}>
                                <Buttons title={'Yes'} onClick={onConfirm} />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default CustomModel