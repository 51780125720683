import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Api from '../services/Api';
import { Icon } from 'react-icons-kit';
import { trash2 } from 'react-icons-kit/feather/trash2';
import { Alert } from 'react-bootstrap';
import ChatComponent from '../components/ChatComponent';
import { Helmet } from 'react-helmet';

const Chat = (props) => {
  let location = useLocation();
  // const [userData, setUserData] = useState({});
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [num, setNum] = useState(0);
  const [_alert, setAlert] = useState({ varient: '', msg: '' });
  const [chatData, setChatData] = useState(true);
  const [_helmet, setHelmet] = useState({ title: 'Nestheads - chat', des: 'description' })


  const [chat_id, setChat_id] = useState(0)
  const [character_id, setCharacter_id] = useState(null)

  const navigate = useNavigate();

  const setWidth = () => {
    let width = document.body.offsetWidth;
    // alert(width)
    if (width > 1500) {
      setNum(120)
    } else if (width > 1200) {
      setNum(90)

    } else if (width > 900) {
      setNum(60)

    } else if (width > 600) {
      setNum(55)

    } else {
      setNum(20)

    }
  }

  window.addEventListener('load', setWidth);
  window.addEventListener('resize', setWidth)

  useEffect(() => {
    setWidth()
  }, [chats])

  const getCharacterlist = async () => {
    setLoading(true)

    let res = await Api.post('characters/list')
    let chatres = await Api.post('chat/list')

    Promise.all([res, chatres])
      .then((res) => {
        if (res[0].success) {
          let default_id
          let first_id
          res[0].data.map((e, i) => {
            if (e.default) {
              default_id = e
            }
            if (i == 0) {
              first_id = e
            }
          })
          if (default_id) {
            setCharacter_id(default_id.id)
          } else {
            setCharacter_id(first_id.id)
          }
        } if (res[1].success && res[1].data) {
          setChats(res[1].data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)

      })

  }


  useEffect(() => {
    if (props.type == 1) {
      let _loc = location.pathname.split("/");
      setChats([])
      if (_loc[_loc.length - 1] == 'chat') {
        // getChatData()
        getCharacterlist()

      } else {
        setChats([])
        if (_loc[_loc.length - 1].trim() == "") {
          setCharacter_id(_loc[_loc.length - 2]);
        } else {
          setCharacter_id(_loc[_loc.length - 1]);
        }
      }

    } else {
      let _loc = location.pathname.split("/");
      let chat_id = parseInt(_loc[_loc.length - 1]);
      let character_id = parseInt(_loc[_loc.length - 2]);
      setChat_id(_loc[_loc.length - 1])
      setCharacter_id(_loc[_loc.length - 2])

    }
  }, [location, chatData])

  function formatChatDateTime(time) {
    let currentDate = new Date()
    let date = new Date(time)

    const options = {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    };

    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      delete options.day;
    }

    if (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      delete options.month;
    }

    if (date.getFullYear() === currentDate.getFullYear()) {
      delete options.year;
    }

    if (date.getDay() === currentDate.getDay()) {
      delete options.weekday;
    }

    return date.toLocaleString('en-IN', options);
  }

  const deleteChat = (chat_id, character_id) => {
    setAlert({ varient: "", msg: '' })
    Api.post('chat/delete', {
      chat_id,
      character_id
    }).then((res) => {
      if (res.success) {
        let _chats = [];
        chats.map((e) => {
          if (e.character_id == character_id && e.chat_id == chat_id) {

          } else {
            _chats.push(e)
          }
        })
        if (_chats.length == 0) {
          setChatData(false)
          setChat_id(null)
          setLoading(true)
          Api.post('characters/list')
            .then((res) => {
              if (res.success) {

                let default_id
                let first_id
                res.data.map((e, i) => {
                  if (e.default) {
                    default_id = e
                  }
                  if (i == 0) {
                    first_id = e
                  }
                })
                if (default_id) {
                  setCharacter_id(default_id.id)
                } else {
                  setCharacter_id(first_id.id)
                }
              } else {
                setCharacter_id(null)
              }
            })
            .catch((err) => {
              console.log(err)
              setCharacter_id(null)
            })
            .finally(() => {
              setLoading(false)
            })
          // setLoading(true)
        } else {
          setChatData(true)

        }
        setChats(_chats);
        if (_chats.length == 0) {
          setAlert({ varient: "", msg: '' })
        } else {
          setAlert({ varient: 'success', msg: 'Chat deleted successfully!' })
        }
      } else {
        setAlert({ varient: 'danger', msg: 'Some error Occurred!' })
      }
    }).catch((err) => {
      setAlert({ varient: 'danger', msg: 'Some error Occurred!' })

    })

  }

  if (loading) {
    return (
      <div className='chat_page'>
        <Helmet>
          <title>{_helmet.title}</title>
          <meta name="description" content={_helmet.des} />
        </Helmet>
        <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
        <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', width: '100%', margin: '5px 0px' }} ></div>
      </div>
    )
  }

  if (props.type == 1) {
    return (
      <div className='chat_page'>
        <Helmet>
          <title>{_helmet.title}</title>
          <meta name="description" content={_helmet.des} />
        </Helmet>
        {_alert.msg && <Alert variant={_alert.varient} dismissible onClose={() => { setAlert({ varient: "", msg: "" }) }}>
          {_alert.msg}
        </Alert>}
        {
          chats.length == 0 ?
            < ChatComponent chat_id={chat_id} character_id={character_id} />
            :
            chats.map((e, i) => {
              if (e.chat_messages) {
                return (
                  <div className='character_listing' key={i} >
                    <div className='character' onClick={() => { navigate(`/chat/${e.characters.id}-${e.characters.seo}/${e.chat_messages.chat_id}`) }}>
                      <div className="character-image-wrapper"  >
                        <img src={e.characters.image} alt={e.characters.name} />
                      </div>
                      <div>
                        <div className='charater_title' >{e.characters.name} </div>
                        <div className='character_des' ><span style={{ color: 'black' }} >{formatChatDateTime(e.chat_messages.date_time) + ': '}</span>{e.chat_messages.message.length > num ? e.chat_messages.message.slice(0, num - 10) + '...' : e.chat_messages.message || ""} </div>
                      </div>
                    </div>
                    <div className='star-icon' style={{ alignSelf: 'end' }} onClick={() => deleteChat(e.chat_messages.chat_id, e.characters.id)} >
                      <Icon icon={trash2} size={18} />
                    </div>
                  </div>
                )
              }
            })
        }
      </div>
    )
  }

  return (
    <div className='chat_page' >
      <ChatComponent chat_id={chat_id} character_id={character_id} />
    </div>
  );
};

export default Chat
